@import "app/applications/city/frontend/scss/mixins/font-face";

@include font-face("Oswald", "/fonts/oswald-extralight/oswald-extralight", 100, $exts: woff ttf);
@include font-face("Oswald", "/fonts/oswald-light/oswald-light", 300, $exts: woff ttf);
@include font-face("Oswald", "/fonts/oswald-regular/oswald-regular", 400, $exts: woff ttf);
@include font-face("Oswald", "/fonts/oswald-bold/oswald-bold", 600, $exts: woff ttf);
@include font-face("Oswald", "/fonts/oswald-demibold/oswald-demibold", 900, $exts: woff ttf);

@include font-face("Helvetica", "/fonts/helveticaneuecyr-ultralight/helveticaneuecyr-ultralight", 100, $exts: woff ttf);
@include font-face("Helvetica", "/fonts/helveticaneuecyr-thin/helveticaneuecyr-thin", 300, $exts: woff ttf);
@include font-face("Helvetica", "/fonts/helveticaneuecyr-lighthelveticaneuecyr-light", 600, $exts: woff ttf);
@include font-face("Helvetica", "/fonts/helveticaneuecyr-roman/helveticaneuecyr-roman", 700, $exts: woff ttf);
@include font-face("Helvetica", "/fonts/helveticaneuecyr-bold/helveticaneuecyr-bold", 900, $exts: woff ttf);