@import "../modules/vars";

/*==========================
	Default styles for 
	placeholder, textarea 
	and input
============================*/

*, *:before, *:after {
  -webkit-box-sizing: border-box; /* Нужно для мобильного WebKit */
  -moz-box-sizing: border-box; /* Нужно для Firefox */
  box-sizing: border-box;
}

*::-webkit-input-placeholder {
	color: #666;
	opacity: 1;
}

*:-moz-placeholder {
	color: #666;
	opacity: 1;
}

*::-moz-placeholder {
	color: #666;
	opacity: 1;
}

*:-ms-input-placeholder {
	color: #666;
	opacity: 1;
}

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: #666;
}
body input:required:valid,
body textarea:required:valid {
	color: #666;
}

.visuallyhidden {
	display: none;
}

svg {
	display: inline;
}

/*==========================
	Alignment
============================*/
.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-bold {
	font-weight: 800;
}

.text-underline {
	border-bottom: 2px solid $accent;
}

.text-accent {
  color: $accent;
}

.text-highlightblue {
	color: $blue;
}

.table {
  display: table;
  width: 100%;
}

.table-cell {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.block {
	display: block;

	@include bp-md {
		display: inline;
	}
}

.section-padding {
	padding: 4em 0;

	@include bp-sm {
		padding: 2.5em 0;
	}
}

/*==========================
	Heading in Sections
============================*/


/*==========================
	Background color For Sections
============================*/
.section-white {
	background-color: #fff;
}

.section-grey {
	background-color: $gray;
}

.section-img {
	background-size: cover;
	background-repeat: no-repeat;
}


/*==========================
	Img Block in Sections
============================*/
.part-img {
	text-align: center;
}

img {
  max-width: 100%;
}

iframe {
	max-width: 100%;
}