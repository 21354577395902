
@mixin trans($property) {
	transition: $property .35s ease;
}

//1200px
@mixin bp-lg {
  @media only screen and (max-width: 75em) {
    @content;
  }
}

//992px
@mixin bp-md {
  @media only screen and (max-width: 62em) {
    @content;
  }
}

//768px
@mixin bp-sm {
  @media only screen and (max-width: 48em) {
    @content;
  }
}