@import "../modules/vars";

/*==========================
  You can set typography here: https://www.gridlover.net/try
============================*/

html, .root {
  font-size: 16px;
  line-height: 24px;
}

body {
	font-size: 1em;
	min-width: 320px;
	position: relative;
	line-height: 1.5em;
	font-family: $default-font;
	overflow-x: hidden;
}

h1, .h1 {
  font-size: 2.75em;
  line-height: 1em;
  margin-top: 0;
  margin-bottom: 0;
}
h2, .h2 {
  font-size: 2.05555556em;
  line-height: 1.45945946em;
  margin-top: 0.72972973em;
  margin-bottom: 0.72972973em;
}
h3, .h3 {
  font-size: 1.44444444em;
  line-height: 1.03846154em;
  margin-top: 1.03846154em;
  margin-bottom: 0em;
}
h4, .h4 {
  font-size: 1em;
  line-height: 1.5em;
  margin-top: 1.5em;
  margin-bottom: 0em;
}
h5, .h5 {
  font-size: 1em;
  line-height: 1.5em;
  margin-top: 1.5em;
  margin-bottom: 0em;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  font-family: $second-font;
}

p, ul, ol, pre, table, blockquote {
  margin-top: 0em;
  margin-bottom: 1.5em;
}
ul ul, ol ol, ul ol, ol ul {
  margin-top: 0em;
  margin-bottom: 0em;
}

p {
	font-size: 1em;
}

/* Let's make sure all's aligned */
hr, .hr {
  border: 1px solid;
  margin: -1px auto;
}
a, b, i, strong, em, small, code {
  line-height: 0;
}
sub, sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}